<template>
  <div class="content-section">
    <el-row class="form-row">
      <el-col :span="6">
        <el-card shadow="hover" class="profile-card">
          <h5 class="heading">Profile settings</h5>
          <div class="my-3">
            <el-tooltip content="Add Profile Picture">
              <img
                :src="profilePictureUrl"
                alt="Avatar"
                width="120"
                style="
                  border-radius: 50%;
                  margin-right: 40px;
                  margin-top: 20px;

                  height: 170px;
                  width: 170px;
                  object-fit: cover;
                "
                class="inline-block"
                @click="dialogVisible = true"
              />
            </el-tooltip>
            <span style="margin-top: 20px; margin-left: -45px">
              <el-button
                class="el-button"
                style="
                  background-color: ivory;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  align-items: center;
                  justify-content: center;
                "
                type="plain"
                size="small"
                circle
                @click="deleteProfilePicture"
              >
                <img width="15" src="@/assets/Trash.svg" alt="profile" />
              </el-button>
            </span>
          </div>

          <el-dialog
            class="custom-dialog"
            title="Update Profile Picture"
            v-model="dialogVisible"
            width="30%"
            :before-close="handleClose"
          >
            <el-upload
              class="upload-demo"
              :before-upload="beforeUpload"
              :on-change="handleFileChange"
              :auto-upload="false"
              :action="null"
              ref="upload"
            >
              <template v-slot:trigger>
                <el-button size="small" type="primary">Select File</el-button>
              </template>

              <template v-slot:tip>
                <div class="el-upload__tip">Only jpg/png files are allowed</div>
              </template>
            </el-upload>
            <div v-if="imageUrl" class="image-preview">
              <img
                :src="imageUrl"
                alt="Profile Picture Preview"
                style="width: 100%; margin-top: 10px"
              />
            </div>

            <el-button
              type="primary"
              style="margin-top: 10px"
              :disabled="!isUploadEnabled"
              @click="uploadFiles"
            >
              Upload
            </el-button>
            <template #footer>
              <div class="footer-class">
                <!-- You can add buttons here if needed -->
              </div>
            </template>
          </el-dialog>

          {{ formData.firstname }}&nbsp;{{ formData.lastname }}
          <hr />
          <div class="menu-wrapper">
            <h5 class="hoverable">My profile</h5>
            <h5 class="hoverable">Notifications</h5>
            <h5 class="hoverable">Advanced</h5>
            <h5 class="hoverable">Data Management</h5>
            <h5 class="hoverable">Billing Members</h5>
            <h5 class="hoverable">Usage</h5>
            <h5 class="hoverable">Members</h5>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card shadow="hover" class="profile-content-card">
          <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-position="top"
            size="large"
            class="bold-text"
          >
            <!-- Form items here -->
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="Email" prop="email" class="form-items">
                  <el-input
                    v-model="formData.email"
                    placeholder="Enter your email"
                    class="form-input"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Password"
                  prop="password"
                  class="form-items"
                >
                  <el-input
                    v-model="formData.password"
                    type="password"
                    placeholder="Enter your password"
                    class="form-input"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="First Name"
                  prop="firstname"
                  class="form-items"
                >
                  <el-input
                    v-model="formData.firstname"
                    placeholder="Enter your First Name"
                    class="form-input"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Last Name" class="form-items">
                  <el-input
                    v-model="formData.lastname"
                    placeholder="Enter your Last Name"
                    class="form-input"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Contact" class="form-items">
                  <el-input
                    v-model="formData.contact"
                    placeholder="Enter your contact"
                    class="form-input"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Website URL" class="form-items">
                  <el-input
                    v-model="formData.website"
                    placeholder="Enter your website URL"
                    class="form-input"
                  ></el-input>
                </el-form-item>
                <el-form-item class="submit-container">
                  <el-button
                    type="primary"
                    @click="submitForm"
                    class="submit-button"
                    >Save Changes</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import {
  ElRow,
  ElCol,
  ElInput,
  ElForm,
  ElFormItem,
  ElButton,
  ElCard,
  ElTooltip,
  ElDialog,
  ElUpload,
} from "element-plus";

export default {
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElForm,
    ElFormItem,
    ElButton,
    ElCard,
    ElTooltip,
    ElDialog,
    ElUpload,
  },
  data() {
    return {
      formData: {
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        contact: null,
        website: null,
      },
      fileList: [],
      rules: {
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
          {
            type: "email",
            message: "Invalid email address",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          { required: true, message: "Password is required", trigger: "blur" },
        ],
        firstname: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
      },

      imageUrl: null,
      dialogVisible: false,
      profilePictureUrl: "@/assets/profile.png",
    };
  },
  computed: {
    ...mapGetters("globalApis", ["getUserData", "getProfilePicture"]),
    isUploadEnabled() {
      return !!this.imageUrl;
    },
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    async fetchUserData() {
      try {
        this.loading = true;
        await store.dispatch("globalApis/getUserData");
        let response = this.getUserData;
        this.loading = false;
        if (this.getUserData.status) {
          this.formData = {
            email: response.data.email || "",
            password: "",
            firstname: response.data.firstName || "",
            lastname: response.data.lastName || "",
            contact: response.data.mobile || "",
            website: "",
          };
          this.profilePictureUrl =
            response.data.avatar || "@/assets/profile.png";
        } 
      } catch (error) {
        return error
      }
    },

    beforeUpload(file) {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        this.$message.error("Only JPG/PNG files are allowed.");
      }
      return isJpgOrPng;
    },
    handleFileChange(file, fileList) {
      if (fileList.length > 0) {
        this.imageUrl = URL.createObjectURL(file.raw);
      } else {
        this.imageUrl = null;
      }
      this.fileList = fileList;
    },
    async uploadFiles() {
    if (this.fileList.length > 0) {
        const customFile = this.fileList[0];
        const file = customFile.raw;
        const fileType = file.type;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileType", fileType);
        formData.append("fileName", file.name);
        formData.append("fileSize", file.size);
        formData.append("fileLastModified", file.lastModified);
        try {
          const response = await store.dispatch(
            "globalApis/getProfilePicture",
            formData
          );
          if (response) {
            this.getProfilePicture = response.data.avatar;
            this.profilePictureUrl=response.data.avatar
            this.dialogVisible = false;
            this.$notify({
              title: "Success",
              message: "Profile picture uploaded successfully",
              type: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              message: "Failed to upload profile picture",
              type: "error",
            });
          }
        } catch (error) {
          this.$notify({
            title: "Error",
            message: "Error uploading profile picture",
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "Error",
          message: "No file selected",
          type: "error",
        });
      }
    },

    async deleteProfilePicture() {
      try {
        await store.dispatch("profile/deleteProfilePicture");
        this.profilePictureUrl = "@/assets/profile.png";
      } catch (error) {
        return error
      }
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this dialog?")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>

<style scoped>
.form-items :deep(.el-form-item__label) {
  font-weight: 700;
}
/* .custom-dialog { */
/* Styling the dialog wrapper */
.el-dialog__header {
  background-color: #04070b; /* Light gray background */
  color: #333; /* Dark text color */
}

.el-dialog__body {
  font-family: "Arial", sans-serif;
  font-size: 14px;
  color: #555;
}

.el-button {
  border-radius: 4px;
}

.el-button--primary {
  background-color: #040a11;
  border-color: #6b52ae;
}

.el-button--primary:hover {
  background-color: #6b52ae;
  border-color: #40e42688;
}
/* } */

.el-input__inner {
  width: 100px;
}
.profile-card {
  margin-right: 20px;
}
.myProfile :deep(.el-tabs__header .is-left) {
  font-size: 15px;
}
.heading {
  font-weight: 600;
}
.el-button {
  margin-top: 150px;
  margin-left: -40px;
}
.header h3 {
  margin-left: -620px;
  margin-bottom: 20px;
  font-size: 24px;
  color: #409eff;
}
.bold-text {
  font-weight: bold;
}
/* Add a container to wrap the hoverable items */
.menu-wrapper {
  width: 220px; /* Adjust the width as needed */
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 21px;
  display: flex;
  flex-direction: column;
}

/* Style for each hoverable item */
.hoverable {
  font-size: 16px;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 11px;
  border-radius: px;
  margin-bottom: 10px; /* Add space between items */
  text-align: left; /* Ensure text is aligned to the left */
  background-color: #fff; /* Light background for better visibility */
}

/* Hover effect */
.hoverable:hover {
  color: #6b52ae;
  background-color: #e0e0e0;
}
/* Hover effect */
.hoverable:hover {
  color: #fff;
  background-color: #6b52ae; /* You can adjust the hover background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.el-form-item {
  margin-bottom: 24px;
}
.el-form-item .el-form-item__label {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  color: #555;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
}
.form-input {
  width: 100%;
  padding: 1px 15px;
  height: 20%;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.form-input:focus {
  border-color: #409eff;
  box-shadow: 0 0 8px rgba(64, 158, 255, 0.2);
}

.el-input__inner {
  border-radius: 8px;
}

.submit-container {
  text-align: left;
  margin-top: 20px;
}

.submit-button {
  margin-top: -10px;
  margin-inline: 4px;
  width: 100%;
  max-width: 150px;
  padding: 20px;
  border-radius: 1000px;
}

.manImg {
  width: 38px;
  height: 5.5%;
}

@media (min-width: 1200px) {
  .form-input {
    min-width: 600px;
  }
}

@media (max-width: 1199px) {
  .form-input {
    min-width: 100%;
  }
}

@media (max-width: 767px) {
  .form-input {
    min-width: 100%;
    padding: 8px 12px;
  }

  .submit-button {
    max-width: 100%;
  }
}
</style>
